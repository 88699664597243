const Events = () => {
  return (
    <div className="text-center md:mx-auto  px-[32px]">
      <div className="md:px-6 mb-12 md:grid md:grid-cols-2 md:place-items-center">
        <img
          src="/assets/event.jpg"
          alt=""
          className="mt-24 mx-auto block md:w-[400px]"
        />
        <div className="">
          <h1 className="text-3xl  mt-6 mb-4">Wednesday Of wonders</h1>
          <h3 className=" mb-3 ">
            At our 'Wednesday of Wonders' program, participants embark on a
            transformative journey where the divine presence and the remarkable
            power of the Holy Spirit are experienced in a truly extraordinary
            manner. These gatherings are divinely appointed, marked by the
            abundant grace of salvation, miraculous healings, liberating
            deliverances, and the palpable presence of the Holy Spirit.
          </h3>

          <h4 className=" mb-3 ">
            The heart of our 'Wednesday of Wonders' program pulsates with three
            pillars: worship, the word, and miracles. Each component weaves
            together to create an environment where the supernatural becomes
            natural, where the miraculous becomes an everyday occurrence. In
            this sacred space, we fervently worship, delve into the profound
            teachings of the Word, and witness awe-inspiring miracles unfold
            before our eyes.
          </h4>
        </div>
      </div>

      <div className="md:px-6 mb-12 md:grid md:grid-cols-2 md:place-items-center">
        <img
          src="/assets/events2.jpg"
          alt=""
          className="mt-24  mx-auto block md:w-[400px]"
        />
        <div className="">
          <h1 className="text-3xl  mt-6 mb-4">Jesus Good Morning Oh</h1>
          <h3 className=" mb-3 ">
            Jesus Good Morning Oh' is the nurturing heart of our ministry, where
            seekers and believers alike find a source of profound encouragement,
            spiritual upliftment, and dedicated prayer support. This program
            serves as a conduit for receiving divine impartation, delivered with
            the same passion and conviction as our beloved Victory Resounding
            Revival Ministries..
          </h3>

          <h4 className=" mb-3 ">
            Just as the sunrise ushers in a new day, 'Jesus Good Morning Oh'
            ushers in a fresh encounter with the Word and the Spirit. It is a
            sacred space where the light of God's truth shines brightly,
            illuminating our paths and strengthening our spirits. Through this
            program, you can bask in the presence of the Lord, draw closer to
            Him, and receive His blessings abundantly.
          </h4>
          <h4 className=" mb-3 ">
            'Jesus Good Morning Oh' extends its reach through the Resounding
            Revival TvNetwork, ensuring that this source of spiritual
            nourishment and inspiration is readily accessible to all. Join us as
            we greet each morning with the warmth of Jesus' love, offering
            encouragement, edification, and a sanctuary of prayer for all who
            seek His grace and mercy." draw closer to Him, and receive His
            blessings abundantly.
          </h4>
        </div>
      </div>

      <div className="md:px-6 mb-12 md:grid md:grid-cols-2 md:place-items-center">
        <img
          src="/assets/events3.jpg"
          alt=""
          className="mt-24  mx-auto block md:w-[400px]"
        />
        <div className="">
          <h1 className="text-3xl mt-6  mb-4">Live-streamed Events</h1>
          <h3 className=" mb-3 ">
            We globally broadcast many of our services from around the worldwide
            through livestreams. People can join from almost any nation in the
            world and encounter the presence and power of the Holy Spirit.
            Through live-streamed events, the power of the Holy Spirit is sent
            right into the home
          </h3>
        </div>
      </div>

      <div className="md:px-6 mb-12 md:grid md:grid-cols-2 md:place-items-center">
        <img
          src="/assets/events4.jpg"
          alt=""
          className="mt-24  mx-auto block md:w-[400px]"
        />
        <div className="">
          <h1 className="text-3xl  mt-6 mb-4">PRAY-NOW</h1>
          <h3 className=" mb-3 ">
            an acronym for 'Night of Worship, Praise, and Prophetic,' is a
            spiritually charged night vigil and fervent prayer program designed
            to ignite the hearts and spirits of believers. It serves as a sacred
            call to prayer and intercession, uniting the faithful in a powerful
            and transformative experience.
          </h3>

          <h4 className=" mb-3 ">
            During PRAY-NOW, we gather to fervently worship, lift our voices in
            joyful praise, and seek the prophetic guidance of the Holy Spirit.
            It is a time to draw near to God, to surrender our concerns, and to
            intercede on behalf of our loved ones, communities, and the world at
            large.
          </h4>

          <h4 className=" mb-3 ">
            As the night unfolds, believers are invited to engage in aggressive
            prayers, invoking the divine presence and invoking God's blessings
            and guidance. PRAY-NOW is a spiritual haven where the power of
            prayer takes center stage, and lives are touched, healed, and
            transformed through the supernatural encounter with the Almighty.
          </h4>

          <h4 className=" mb-3 ">
            Join us at PRAY-NOW, where the night is dedicated to worship,
            praise, and prophetic revelations. It is a sacred time when
            believers are summoned to be vigilant, united, and fervent in their
            pursuit of divine communion and the advancement of God's kingdom.
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Events;
