import { Link } from "react-router-dom";

const BELIEFS = () => {
  return (
    <div className="md:flex text-center px-[32px] ">
      <div
        className="hidden md:flex md:flex-col 
            md:place-items-center mt-24 ml-12 "
      >
        <img src="/logod.png" alt="" />
      </div>
      <div className=" md:w-[650px] mx-auto">
        <h1 className="mt-8 mb-3 text-2xl font-bold text-center leading-10 text-gray-800">
          VREALM Statement of Faith
        </h1>
        <hr />
        <div className="px-4 md:px-8">
          <h1 className="text-2xl text-gray-800 mt-12 mb-6">
            The Exclusivity of Jesus Christ
          </h1>

          <h3 className="text-xl text-center  text-gray-600">
            We believe that salvation from sin and Hell is found exclusively in
            trust and obedience toward Jesus Christ. His sacrifice was the
            payment for the sins of mankind. He is the only way to God, Truth,
            and eternal life in Heaven. (John 14:6)
          </h3>
        </div>
        <div className="px-8">
          <h1 className="text-2xl text-gray-800 mt-12 mb-6">
            The Identity of Jesus Christ
          </h1>
          <h3 className="text-xl text-gray-600">
            We believe that Jesus Christ was God in the flesh. (John 1:1,14)
          </h3>
        </div>
        <div className="px-8">
          <h1 className="text-2xl text-gray-800 mt-12 mb-6">
            The Word of Jesus Christ
          </h1>
          <h3 className="text-xl  text-gray-600">
            We believe that the Bible is the Word of God. It is without error in
            truth and useful for guidance in all aspects of ministry, life,
            spirituality, and understanding God’s ways. (Psalm 18:30)
          </h3>
        </div>
        <div className="px-8">
          <h1 className="text-2xl text-gray-800 mt-12 mb-6">
            The Resurrection of Jesus Christ
          </h1>
          <h3 className="text-xl  text-gray-600">
            Jesus resurrected in bodily form, validating His message, identity,
            and power. (John 20:24-29)
          </h3>
        </div>
        <div className="px-8 mb-24">
          <h1 className="text-2xl text-gray-800 mt-12 mb-6">
            The Fullness of Jesus Christ
          </h1>
          <h3 className="text-xl  text-gray-600">
            We believe in the fullness of Jesus Christ and the gifts of His Holy
            Spirit. He is Savior, Healer, Deliverer, and Baptizer. Everything
            that He did in bodily form, He still performs through His Holy
            Spirit. (John 14:12; Acts 10:38; Mark 1:8; Hebrews 13:8)
          </h3>
        </div>
      </div>
    </div>
  );
};

export default BELIEFS;
